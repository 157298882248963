import {
  ref, onMounted, getCurrentInstance, reactive, computed, watch, onBeforeUnmount
} from 'vue'
import { useStore } from 'vuex'
import { transScheduleBusUseCase, deliveryManifestUseCase } from '@/domain/usecase'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
// import VuePaginateSrcoll from 'vue-paginate-scroll'
// import VScrollPaginate from 'v-scroll-paginate'

export default {
  components: {
    // VScrollPaginate
  },
  props: {
    isShowDialog: {
      default: () => false
    },
    title: {
      default: () => 'Cek Resi Manual'
    },
    listAgent: {
      default: () => []
    },
    maxShow: {
      default: () => 40
    }
  },
  emits: ['hideDialog', 'sendOutData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $numbers, $toast, $strInd, $confirm
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const router = useRouter()
    const isShowDialog = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })
    const title = computed(() => propertiesProps.title)
    const store = useStore()
    const dateOpRef = ref()
    const dateFilter = ref(moment().format('YYYY-MM-DD'))

    const hideDialog = () => {
      emit('hideDialog')
    }
    const inputSearch = ref('')
    const data = ref([])
    const filters = reactive({
      search: inputSearch.value,
      filter: '',
      sorting: 'TanggalAktif desc',
      date: dateFilter.value,
      expand: '',
      totalRecords: 0,
      top: 0,
      skip: $numbers.totalItemPerPage,
      rit: [] as any
    })
    const getAllData = async () => {
      store.dispatch('showLoading')
      filters.search = inputSearch.value
      if (inputSearch.value !== '') {
        filters.skip = 0
      }

      const response = await deliveryManifestUseCase.getListManifestV2(filters)
      if (!response.error) {
        filters.totalRecords = response.count
        data.value = response.result
      } else {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }
    onMounted(() => {
      getAllData()
    })
    const showDetail = (id: any) => {
      const dataEnc = encryptDecriptMethods.encrypt(Number(id))
      router.push({
        name: 'receipt-checking-detail',
        params: {
          id: dataEnc
        }
      })
    }
    const showDateFilterAct = (evt: any) => {
      dateOpRef.value.toggle(evt)
    }
    const saveFilterDateAct = (evt: any) => {
      filters.date = moment(dateFilter.value).format('YYYY-MM-DD')
      dateOpRef.value.toggle(evt)
      getAllData()
    }
    const clearFilterDateAct = (evt: any) => {
      filters.date = moment().format('YYYY-MM-DD')
      dateOpRef.value.toggle(evt)
      getAllData()
    }
    return {
      title,
      isShowDialog,
      store,
      hideDialog,
      inputSearch,
      data,
      moment,
      getAllData,
      filters,
      showDetail,
      dateOpRef,
      dateFilter,
      showDateFilterAct,
      saveFilterDateAct,
      clearFilterDateAct,
    }
  }
}