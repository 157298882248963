
import { toRefs } from 'vue'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

export default {
  props: ['data'],
  setup(props: any) {
    const item = toRefs(props).data
    const router = useRouter()
    const showDetail = (val: any) => {
      // console.log('data click', val)
      const dataEnc = encryptDecriptMethods.encrypt(val)
      router.push({
        name: 'receipt-checking-detail',
        params: {
          id: dataEnc
        }
      })
    }

    return {
      item,
      moment,
      showDetail
    }
  }
}
